<template>
  <div class="processingDetail content">
    <!-- 二维码申请 -->
    <div>
      <el-form ref="form" :model="form" label-width="90px">
        <p class="title"><span class="sign"></span>申请信息</p>
        <div class="itemBox">
          <div class="itemCon">
            <p class="label">申请类型：</p>
            <p>{{ detaileData.applytypename }}</p>
          </div>
          <div class="itemCon">
            <p class="label">用途说明：</p>
            <p>{{ detaileData.applymemo }}</p>
          </div>
          <div class="itemCon">
            <p class="label">申请人：</p>
            <p>{{ detaileData.applyusername }}</p>
          </div>
          <div class="itemCon">
            <p class="label">申请时间：</p>
            <p>{{ detaileData.applytime }}</p>
          </div>
        </div>
        <p class="title"><span class="sign"></span>审核</p>
        <div class="itemBox">
          <el-form-item label="审核结果：">
            <el-select
              v-model="form.result"
              placeholder="请选择审核结果"
              size="small"
            >
              <el-option label="审核通过" value="2"></el-option>
              <el-option label="不通过" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="说明：">
            <el-input
              type="textarea"
              v-model="form.checkview"
              placeholder="对审核不通过的原因说明或者其他说明"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="审核人：">
            <el-input v-model="userAccount" size="small" readonly></el-input>
          </el-form-item>
        </div>
        <div v-if="showTime">
          <p class="title"><span class="sign"></span>视频权限设置</p>
          <div class="itemBox">
            <p class="red tips">设置授权时效，如果不设置时间，则为永久有效!</p>
            <el-form-item label="开始时间：">
              <el-date-picker
                v-model="form.begintime"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择开始时间"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间：">
              <el-date-picker
                v-model="form.endtime"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择结束时间"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
        <div class="button" @click="submit">保存</div>
      </el-form>
    </div>
    <!-- 授权审核 -->
    <!-- <div v-else>
      <p class="title"><span class="sign"></span>申请信息</p>
      <div class="itemBox">
        <div class="itemCon">
          <p class="label">申请类型：</p>
          <p>{{ detaileData.applytypename }}</p>
        </div>
        <div class="itemCon">
          <p class="label">用途说明：</p>
          <p>{{ detaileData.applymemo }}</p>
        </div>
        <div class="itemCon">
          <p class="label">申请人：</p>
          <p>{{ detaileData.applyusername }}</p>
        </div>
        <div class="itemCon">
          <p class="label">申请时间：</p>
          <p>{{ detaileData.applytime }}</p>
        </div>
      </div>
      <p class="title"><span class="sign"></span>审核</p>
      <div class="itemBox">
        <el-form-item label="审核结果：">
          <el-select
            v-model="form.result"
            placeholder="请选择审核结果"
            size="small"
          >
            <el-option label="审核通过" value="2"></el-option>
            <el-option label="不通过" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="说明：">
          <el-input
            type="textarea"
            v-model="form.checkview"
            placeholder="对审核不通过的原因说明或者其他说明"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核人：">
          <el-input v-model="userAccount" size="small" readonly></el-input>
        </el-form-item>
      </div>
      <div v-if="showTime">
        <p class="title"><span class="sign"></span>视频权限设置</p>
        <div class="itemBox">
          <p class="red tips">设置授权时效，如果不设置时间，则为永久有效!</p>
          <el-form-item label="开始时间：">
            <el-date-picker
              v-model="form.begintime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择开始时间"
              size="small"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：">
            <el-date-picker
              v-model="form.endtime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择结束时间"
              size="small"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "processingDetail",
  props: {
    popupData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      detaileData: {},
      form: {
        result: "2",
        checkview: "",
        begintime: "",
        endtime: ""
      },
      userAccount: "",
      showTime: true
    };
  },
  watch: {
    "form.result"(val) {
      if (val == "1") {
        this.showTime = false;
      } else {
        this.showTime = true;
      }
    }
  },
  mounted() {
    this.userAccount = sessionStorage.getItem("userAccount");
    console.log("popupData", this.popupData);
    this.getUserApplyById();
  },
  methods: {
    getUserApplyById() {
      const params = {
        pkId: this.popupData.pkId
      };
      this.$api("videoManage.getUserApplyById")
        .invoke(params)
        .then(({ data: { data } }) => {
          this.detaileData = data;
        });
    },
    submit() {
      this.$api("videoApproval.examine")
        .invoke({ ...this.form, pkId: this.popupData.pkId })
        .then(({ data: { data } }) => {
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success",
            duration: 1000
          });
          this.$emit("dialogClose");
          this.$emit("refreshTableList");
        });
      console.log("保存");
    }
  }
};
</script>
<style scoped lang="less">
.processingDetail {
  padding: 12px;
  .title {
    color: #6294ff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .sign {
      flex-shrink: 0;
      width: 0;
      height: 0;
      padding: 8px 2px;
      border-radius: 1px;
      margin-right: 8px;
      background: #6294ff;
    }
  }
  .itemBox {
    background: #f2f2f2;
    padding: 12px;
    margin-bottom: 15px;
    position: relative;
    .itemCon {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .label {
        width: 115px;
        text-align: right;
      }
      .qrcode {
        display: flex;
        align-items: center;
        flex-flow: column;
        img {
          width: 100px;
          height: 100px;
        }
        .download {
          cursor: pointer;
        }
      }
    }
    .button {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 10px 16px;
      border-radius: 3px;
      font-size: 14px;
    }
    .qrcode {
      // position: absolute;
      // right: 20px;
      // bottom: 7px;
      // display: flex;
      // flex-flow: column;
      // align-items: center;
      // img {
      //   width: 100px;
      //   height: 100px;
      // }
      // .download {
      //   cursor: pointer;
      // }
    }
    .pass {
      border: 1px solid #3fcc00;
      color: #3fcc00;
    }
    .noPass {
      border: 1px solid #f40000;
      color: #f40000;
    }
    .red {
      color: #f00f00;
      font-size: 14px;
    }
    .tips {
      margin-bottom: 10px;
    }
  }
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  .button {
    display: inline-block;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    padding: 10px 50px;
    border-radius: 3px;
    margin-bottom: 30px;
    margin-left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}
</style>
